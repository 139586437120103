import styled from 'styled-components';

const AboutMeFooterStyled = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 6vh;
  line-height: 6vh;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  border: 2px solid #e6f3ff;
  background-color: #e4ebf8;
`;

export { AboutMeFooterStyled };
